import { Component, OnInit } from '@angular/core';
import { AuthService, LoggingService, MemberQuery, LoginType } from '@fgb/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fgb-single-sign-on',
  templateUrl: './single-sign-on.component.html',
  styleUrls: ['./single-sign-on.component.scss'],
})
export class SingleSignOnComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private memberQuery: MemberQuery,
    private router: Router,
    private logService: LoggingService
  ) {}

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.auth.logout().toPromise();
    }
    let entityType: number = parseInt(this.getQueryString('entityType', '0'));
    let entity: string = this.getQueryString('entity', '');
    let hashedValue: string = this.getQueryString('hashedValue', '');
    let loginType: number = parseInt(this.getQueryString('loginType', '0'));
    let loginDateString = this.getQueryString('loginDate', '');
    let providerId: number = parseInt(this.getQueryString('providerId', '0'));
    let returnUrl: string = this.getQueryString('returnUrl', '');
    let clientTypeId: number = parseInt(this.getQueryString('clientType', '0'));

    let loginDate: Date = new Date();
    if (loginDateString) {
      // Mitigate varying formats with different separators between the date segments
      let parts: number[] = loginDateString.split(/[- :T+]/).map((x) => +x);
      loginDate = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    }

    this.logService.debug('SSO login data', {
      entityType,
      entity,
      hashedValue,
      loginType,
      loginDateString,
      loginDate,
      providerId,
      clientTypeId,
    });

    if (entityType == null) {
      this.logService.info('Attempting single sign on without login...');
      this.auth.sso();
      this.router.navigate([returnUrl]);
    } else {
      this.logService.info('Attempting single sign on with login...');
      this.auth.ssoLogin(entityType, entity, loginDate, hashedValue, 1, loginType, returnUrl, providerId, clientTypeId).subscribe(
        (result) => {
          this.logService.info('Successful SSO login');
          this.logService.debug('SSO login result data', result);

          if (this.memberQuery.getLoginType() != LoginType.Admin) {
            this.auth.checkApiStatus();
          }

          this.router.navigate([returnUrl]);
        },
        (error) => {
          if (error.status === 503) {
            this.logService.warning('503 [Service Unavailable] received; Maintenance mode is probably on', error);
            this.router.navigate(['maintenance']);
          } else {
            this.logService.error('Failed SSO login', error);
            this.router.navigate(['login']);
          }
        }
      );
    }
  }

  /** Get a query string parameter without case sensitivity. */
  private getQueryString(parameter: string, defaultValue: string): string {
    let matchedQueryStringParameter = this.route.snapshot.queryParamMap.keys.find(
      (param) => param.toUpperCase() === parameter.toUpperCase()
    );
    if (matchedQueryStringParameter === undefined) {
      return defaultValue;
    }
    return this.route.snapshot.queryParamMap.get(matchedQueryStringParameter) || defaultValue;
  }
}
